var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A rB",
      "132": "B"
    },
    B: {
      "1": "C K L G M N O",
      "4": "P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "2": "sB gB tB",
      "4": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "8": "I i uB"
    },
    D: {
      "2": "I i J",
      "4": "0 1 2 3 4 5 6 7 8 9 D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "2": "I i J D E F A B C K L G xB lB yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "4": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "2": "lB BC",
      "4": "E qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "2": "WC XC YC",
      "4": "gB I H ZC qB aC bC"
    },
    J: {
      "2": "D",
      "4": "A"
    },
    K: {
      "1": "C fB",
      "2": "A B eB pB",
      "4": "U"
    },
    L: {
      "4": "H"
    },
    M: {
      "4": "T"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "4": "cC"
    },
    P: {
      "4": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "4": "oC"
    },
    R: {
      "4": "pC"
    },
    S: {
      "4": "qC"
    }
  },
  B: 4,
  C: "DeviceOrientation & DeviceMotion events"
};
export default exports;