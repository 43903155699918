var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D rB",
      "66": "E F A"
    },
    B: {
      "1": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB",
      "2": "sB gB I i J D E F A B C K L G M N O j k l m tB uB",
      "66": "n",
      "129": "TB UB VB WB XB YB ZB aB bB cB",
      "388": "dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V",
      "2": "I i J D E F A B C K L G M N O j k l",
      "1540": "W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "D E F A B C K 0B 1B mB eB fB",
      "2": "I i J xB lB yB zB",
      "513": "L G 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB fB",
      "2": "F B C 6B 7B 8B 9B eB pB AC",
      "1540": "YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "E CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "2": "lB BC qB"
    },
    H: {
      "1": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "1": "A",
      "2": "D"
    },
    K: {
      "1": "U fB",
      "2": "A B C eB pB"
    },
    L: {
      "1": "H"
    },
    M: {
      "129": "T"
    },
    N: {
      "1": "B",
      "66": "A"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 6,
  C: "TLS 1.1"
};
export default exports;