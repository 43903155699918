var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F rB",
      "132": "A B"
    },
    B: {
      "132": "C K L G M N O",
      "1028": "P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "2": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w tB uB",
      "1028": "UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2564": "0 1 2 3 4 5 6 7 8 9 x y z AB BB CB",
      "3076": "DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB"
    },
    D: {
      "16": "I i J D",
      "132": "0 1 2 3 4 5 6 7 8 9 F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB",
      "388": "E",
      "1028": "iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "16": "I xB lB",
      "132": "i J D E F A yB zB 0B 1B mB",
      "1028": "B C K L G eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "132": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB",
      "1028": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "16": "lB BC qB",
      "132": "E CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "132": "I ZC qB aC bC",
      "292": "gB WC XC YC",
      "1028": "H"
    },
    J: {
      "16": "D",
      "132": "A"
    },
    K: {
      "2": "A B C eB pB fB",
      "1028": "U"
    },
    L: {
      "1028": "H"
    },
    M: {
      "1028": "T"
    },
    N: {
      "132": "A B"
    },
    O: {
      "132": "cC"
    },
    P: {
      "132": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "132": "oC"
    },
    R: {
      "132": "pC"
    },
    S: {
      "2564": "qC"
    }
  },
  B: 4,
  C: "DOMMatrix"
};
export default exports;