var exports = {};
exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "J D rB",
      "260": "E"
    },
    B: {
      "1": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB tB uB",
      "2": "sB gB",
      "516": "0 1 2 3 4 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "2": "I i J D E F A B C K"
    },
    E: {
      "1": "i J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B",
      "2": "I xB lB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "F B C 6B 7B 8B 9B eB pB AC",
      "4": "fB"
    },
    G: {
      "1": "E qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "16": "lB BC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "gB I H YC ZC qB aC bC",
      "16": "WC XC"
    },
    J: {
      "1": "A",
      "132": "D"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 1,
  C: "Online/offline status"
};
export default exports;