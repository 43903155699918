var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E rB",
      "8": "F A B"
    },
    B: {
      "1": "N O P Q R S V W X Y Z a b c d e f g T h H",
      "8": "C K L G M"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB tB uB",
      "2": "sB gB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "2": "I i J D E F A B C K L G xB lB yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S 8B 9B eB pB AC fB",
      "2": "F 6B 7B"
    },
    G: {
      "2": "E lB BC qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "2": "gB I H WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C U eB pB fB"
    },
    L: {
      "2": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "8": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "2": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "2": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 6,
  C: "Ogg/Theora video format"
};
export default exports;