var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "P Q R S V W X Y Z a b c d e f g T h H",
      "2": "C K L G M N O"
    },
    C: {
      "2": "0 1 2 3 4 5 6 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z tB uB",
      "260": "7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "1": "OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "2": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB",
      "324": "FB GB HB IB JB KB LB MB hB NB iB"
    },
    E: {
      "2": "I i J D E F A xB lB yB zB 0B 1B mB",
      "132": "B C K L G eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "F B C G M N O j k l m n o p q r s t u v w x y z 6B 7B 8B 9B eB pB AC fB",
      "324": "0 1 2 3 4 5 6 7 8 9 AB BB"
    },
    G: {
      "2": "E lB BC qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "260": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "132": "cC"
    },
    P: {
      "1": "gC hC mB iC jC kC lC mC nC",
      "2": "I",
      "132": "dC eC fC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "2": "pC"
    },
    S: {
      "260": "qC"
    }
  },
  B: 5,
  C: "Media Capture from DOM Elements API"
};
export default exports;