var exports = {};
exports = {
  A: {
    A: {
      "16": "rB",
      "132": "J D E F A B"
    },
    B: {
      "1": "O P Q R S V W X Y Z a b c d e f g T h H",
      "132": "C K L G M N"
    },
    C: {
      "1": "KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "132": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s tB uB",
      "260": "GB HB IB JB",
      "772": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB DB EB FB"
    },
    D: {
      "1": "VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "132": "I i J D E F A B C K L G M N O j k l m n",
      "260": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB",
      "772": "0 1 o p q r s t u v w x y z"
    },
    E: {
      "1": "C K L G fB 2B 3B 4B nB oB 5B",
      "16": "I i xB lB",
      "132": "J D E F A yB zB 0B 1B",
      "260": "B mB eB"
    },
    F: {
      "1": "LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "16": "F B C 6B 7B 8B 9B eB pB AC",
      "132": "fB",
      "260": "0 1 2 3 4 5 6 7 8 9 p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB",
      "772": "G M N O j k l m n o"
    },
    G: {
      "1": "JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "16": "lB BC qB CC",
      "132": "E DC EC FC GC HC IC"
    },
    H: {
      "132": "VC"
    },
    I: {
      "1": "H",
      "16": "gB WC XC YC",
      "132": "I ZC qB",
      "772": "aC bC"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "U",
      "16": "A B C eB pB",
      "132": "fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "132": "A B"
    },
    O: {
      "260": "cC"
    },
    P: {
      "1": "hC mB iC jC kC lC mC nC",
      "260": "I dC eC fC gC"
    },
    Q: {
      "260": "oC"
    },
    R: {
      "132": "pC"
    },
    S: {
      "132": "qC"
    }
  },
  B: 6,
  C: "Date.prototype.toLocaleDateString"
};
export default exports;