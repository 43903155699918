var exports = {};
exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "rB",
      "8": "J D E"
    },
    B: {
      "1": "C K L G M N O",
      "129": "P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB tB uB",
      "8": "sB gB",
      "129": "JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB",
      "4": "I",
      "129": "EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "i J D E F B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B",
      "8": "I xB lB",
      "129": "A"
    },
    F: {
      "1": "0 1 2 B C M N O j k l m n o p q r s t u v w x y z 9B eB pB AC fB",
      "2": "F G 6B",
      "8": "7B 8B",
      "129": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "E lB BC qB CC DC EC FC GC HC",
      "129": "IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "gB I WC XC YC ZC qB aC bC",
      "129": "H"
    },
    J: {
      "1": "D A"
    },
    K: {
      "1": "B C eB pB fB",
      "8": "A",
      "129": "U"
    },
    L: {
      "129": "H"
    },
    M: {
      "129": "T"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I",
      "129": "dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "129": "oC"
    },
    R: {
      "129": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 2,
  C: "Geolocation"
};
export default exports;