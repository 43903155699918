var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "V W X Y Z a b c d e f g T h H",
      "2": "C K L G M N O",
      "260": "P Q R S"
    },
    C: {
      "1": "R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w tB uB",
      "260": "hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB",
      "516": "BB CB DB EB FB GB HB IB JB KB LB MB",
      "580": "0 1 2 3 4 5 6 7 8 9 x y z AB",
      "2049": "aB bB cB dB P Q"
    },
    D: {
      "1": "V W X Y Z a b c d e f g T h H kB vB wB",
      "2": "I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z",
      "132": "0 1 2",
      "260": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S"
    },
    E: {
      "1": "G 4B nB oB 5B",
      "2": "I i J D E F A xB lB yB zB 0B 1B mB",
      "1090": "B C K eB fB",
      "2049": "L 2B 3B"
    },
    F: {
      "1": "WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "F B C G M N O j k l m 6B 7B 8B 9B eB pB AC fB",
      "132": "n o p",
      "260": "0 1 2 3 4 5 6 7 8 9 q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB"
    },
    G: {
      "2": "E lB BC qB CC DC EC FC GC HC IC JC",
      "1090": "KC LC MC NC OC PC QC",
      "2049": "RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "260": "cC"
    },
    P: {
      "260": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "260": "oC"
    },
    R: {
      "260": "pC"
    },
    S: {
      "516": "qC"
    }
  },
  B: 5,
  C: "Web Animations API"
};
export default exports;