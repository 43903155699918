var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A rB",
      "132": "B"
    },
    B: {
      "1": "P Q R S",
      "132": "C K L G M N O",
      "513": "V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X",
      "2": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z tB uB",
      "513": "Y Z a b c d e f g T h H kB"
    },
    D: {
      "1": "iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V",
      "2": "I i J D E F A B C K L G M N O j k",
      "260": "0 1 2 3 4 5 6 7 8 9 l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB",
      "513": "W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "C eB fB",
      "2": "I i J D xB lB yB zB",
      "132": "E F A B 0B 1B mB",
      "1025": "K L G 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB",
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "513": "YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "MC NC OC PC",
      "2": "lB BC qB CC DC EC",
      "132": "E FC GC HC IC JC KC LC",
      "1025": "QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "513": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "dC eC fC gC hC mB iC jC kC lC mC nC",
      "2": "I"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 4,
  C: "Referrer Policy"
};
export default exports;