var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E rB",
      "260": "F A B"
    },
    B: {
      "132": "P Q R S V W X Y Z a b c d e f g T h H",
      "260": "C K L G M N O"
    },
    C: {
      "2": "sB gB I i tB uB",
      "260": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "16": "I i J D E F A B C K L",
      "132": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "16": "xB lB",
      "132": "I i J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "C AC fB",
      "2": "F 6B 7B 8B 9B",
      "16": "B eB pB",
      "132": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "16": "lB BC",
      "132": "E qB CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "16": "WC XC",
      "132": "gB I H YC ZC qB aC bC"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "C fB",
      "2": "A",
      "16": "B eB pB",
      "132": "U"
    },
    L: {
      "132": "H"
    },
    M: {
      "260": "T"
    },
    N: {
      "260": "A B"
    },
    O: {
      "132": "cC"
    },
    P: {
      "132": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "132": "oC"
    },
    R: {
      "132": "pC"
    },
    S: {
      "260": "qC"
    }
  },
  B: 5,
  C: "Mutation events"
};
export default exports;