var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A rB",
      "388": "B"
    },
    B: {
      "1": "O P Q R S V W",
      "2": "C K L G",
      "129": "M N",
      "513": "X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "0 1 2 3 4 5 6 7 8 9 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB tB uB"
    },
    D: {
      "1": "FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P",
      "2": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB",
      "513": "Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "G 3B 4B nB oB 5B",
      "2": "I i J D E F A B xB lB yB zB 0B 1B mB eB",
      "2052": "L",
      "3076": "C K fB 2B"
    },
    F: {
      "1": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB",
      "2": "0 1 2 F B C G M N O j k l m n o p q r s t u v w x y z 6B 7B 8B 9B eB pB AC fB",
      "513": "WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "OC PC QC RC SC TC UC nB oB",
      "2": "E lB BC qB CC DC EC FC GC HC IC JC KC LC",
      "2052": "MC NC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "513": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "cC"
    },
    P: {
      "1": "dC eC fC gC hC mB iC jC kC lC mC nC",
      "2": "I"
    },
    Q: {
      "16": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "2": "qC"
    }
  },
  B: 6,
  C: "'SameSite' cookie attribute"
};
export default exports;