var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "4": "J D E F A rB"
    },
    B: {
      "1": "C K L G M",
      "129": "N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB",
      "4": "sB gB I i tB uB",
      "129": "FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "1": "9 AB BB CB DB EB FB GB HB IB",
      "4": "I i J",
      "129": "0 1 2 3 4 5 6 7 8 D E F A B C K L G M N O j k l m n o p q r s t u v w x y z JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "4": "I i xB lB",
      "129": "J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "0 1 2 3 4 5 C w x y z eB pB AC fB",
      "4": "F B 6B 7B 8B 9B",
      "129": "6 7 8 9 G M N O j k l m n o p q r s t u v AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "4": "lB BC qB",
      "129": "E CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "4": "VC"
    },
    I: {
      "4": "WC XC YC",
      "129": "gB I H ZC qB aC bC"
    },
    J: {
      "129": "D A"
    },
    K: {
      "1": "C eB pB fB",
      "4": "A B",
      "129": "U"
    },
    L: {
      "129": "H"
    },
    M: {
      "129": "T"
    },
    N: {
      "1": "B",
      "4": "A"
    },
    O: {
      "129": "cC"
    },
    P: {
      "129": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "129": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 1,
  C: "dataset & data-* attributes"
};
export default exports;