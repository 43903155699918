var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F rB",
      "900": "A B"
    },
    B: {
      "1": "N O P Q R S V W X Y Z a b c d e f g T h H",
      "388": "L G M",
      "900": "C K"
    },
    C: {
      "1": "FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB gB tB uB",
      "260": "DB EB",
      "388": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB",
      "900": "I i J D E F A B C K L G M N O j k l m n o p q r s"
    },
    D: {
      "1": "4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "16": "I i J D E F A B C K L",
      "388": "0 1 2 3 p q r s t u v w x y z",
      "900": "G M N O j k l m n o"
    },
    E: {
      "1": "A B C K L G mB eB fB 2B 3B 4B nB oB 5B",
      "16": "I i xB lB",
      "388": "E F 0B 1B",
      "900": "J D yB zB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "16": "F B 6B 7B 8B 9B eB pB",
      "388": "G M N O j k l m n o p q",
      "900": "C AC fB"
    },
    G: {
      "1": "IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "16": "lB BC qB",
      "388": "E EC FC GC HC",
      "900": "CC DC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "16": "gB WC XC YC",
      "388": "aC bC",
      "900": "I ZC qB"
    },
    J: {
      "16": "D",
      "388": "A"
    },
    K: {
      "1": "U",
      "16": "A B eB pB",
      "900": "C fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "900": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "388": "qC"
    }
  },
  B: 1,
  C: "Constraint Validation API"
};
export default exports;