var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "2": "C K L G M N O P",
      "1537": "Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "2": "sB gB tB uB",
      "260": "0 1 2 3 4 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z",
      "513": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P",
      "1537": "Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "2": "I i J D E F A B C K L G xB lB yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "8 9 AB BB CB DB EB FB GB HB",
      "2": "0 1 2 3 4 5 6 7 F B C G M N O j k l m n o p q r s t u v w x y z IB JB KB LB MB NB OB PB U QB RB 6B 7B 8B 9B eB pB AC fB",
      "1537": "SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "2": "MC NC OC PC QC RC SC TC UC nB oB",
      "130": "E lB BC qB CC DC EC FC GC HC IC JC KC LC"
    },
    H: {
      "130": "VC"
    },
    I: {
      "2": "gB I H WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D",
      "130": "A"
    },
    K: {
      "2": "U",
      "130": "A B C eB pB fB"
    },
    L: {
      "1537": "H"
    },
    M: {
      "2": "T"
    },
    N: {
      "130": "A B"
    },
    O: {
      "2": "cC"
    },
    P: {
      "2": "I dC eC fC gC hC mB iC jC",
      "1537": "kC lC mC nC"
    },
    Q: {
      "2": "oC"
    },
    R: {
      "2": "pC"
    },
    S: {
      "513": "qC"
    }
  },
  B: 1,
  C: "SVG favicons"
};
export default exports;