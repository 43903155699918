var exports = {};
exports = {
  A: {
    A: {
      "4": "J D E F A B rB"
    },
    B: {
      "4": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "8": "0 1 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z tB uB"
    },
    D: {
      "4": "0 1 2 3 4 5 6 7 8 9 i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "8": "I"
    },
    E: {
      "4": "i J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B",
      "8": "I xB lB"
    },
    F: {
      "4": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "8": "F B C 6B 7B 8B 9B eB pB AC fB"
    },
    G: {
      "4": "E CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "8": "lB BC qB"
    },
    H: {
      "8": "VC"
    },
    I: {
      "4": "gB I H ZC qB aC bC",
      "8": "WC XC YC"
    },
    J: {
      "4": "A",
      "8": "D"
    },
    K: {
      "4": "U",
      "8": "A B C eB pB fB"
    },
    L: {
      "4": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "4": "A B"
    },
    O: {
      "4": "cC"
    },
    P: {
      "4": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "4": "oC"
    },
    R: {
      "4": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 1,
  C: "Ruby annotation"
};
export default exports;