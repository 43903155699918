var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "16": "rB",
      "132": "J D E F A"
    },
    B: {
      "1": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "132": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s tB uB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "132": "I i J D E F A B C K L G M N O j k l m n"
    },
    E: {
      "1": "A B C K L G mB eB fB 2B 3B 4B nB oB 5B",
      "132": "I i J D E F xB lB yB zB 0B 1B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "16": "F B C 6B 7B 8B 9B eB pB AC",
      "132": "fB"
    },
    G: {
      "1": "IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "132": "E lB BC qB CC DC EC FC GC HC"
    },
    H: {
      "132": "VC"
    },
    I: {
      "1": "H aC bC",
      "132": "gB I WC XC YC ZC qB"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "U",
      "16": "A B C eB pB",
      "132": "fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "1": "B",
      "132": "A"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "dC eC fC gC hC mB iC jC kC lC mC nC",
      "132": "I"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "4": "qC"
    }
  },
  B: 6,
  C: "localeCompare()"
};
export default exports;