var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E rB",
      "2561": "A B",
      "2692": "F"
    },
    B: {
      "1": "P Q R S V W X Y Z a b c d e f g T h H",
      "2561": "C K L G M N O"
    },
    C: {
      "1": "DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "16": "sB",
      "1537": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB uB",
      "1796": "gB tB"
    },
    D: {
      "1": "RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "16": "I i J D E F A B C K L",
      "1025": "0 1 2 3 4 5 6 7 8 9 z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB",
      "1537": "G M N O j k l m n o p q r s t u v w x y"
    },
    E: {
      "1": "L G 2B 3B 4B nB oB 5B",
      "16": "I i J xB lB",
      "1025": "D E F A B C zB 0B 1B mB eB",
      "1537": "yB",
      "4097": "K fB"
    },
    F: {
      "1": "GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S fB",
      "16": "F B C 6B 7B 8B 9B eB pB",
      "260": "AC",
      "1025": "0 1 2 3 4 5 6 7 8 9 m n o p q r s t u v w x y z AB BB CB DB EB FB",
      "1537": "G M N O j k l"
    },
    G: {
      "16": "lB BC qB",
      "1025": "E FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "1537": "CC DC EC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "16": "WC XC",
      "1025": "H bC",
      "1537": "gB I YC ZC qB aC"
    },
    J: {
      "1025": "A",
      "1537": "D"
    },
    K: {
      "1": "A B C eB pB fB",
      "1025": "U"
    },
    L: {
      "1": "H"
    },
    M: {
      "1537": "T"
    },
    N: {
      "2561": "A B"
    },
    O: {
      "1537": "cC"
    },
    P: {
      "1025": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1025": "oC"
    },
    R: {
      "1025": "pC"
    },
    S: {
      "1537": "qC"
    }
  },
  B: 1,
  C: "input event"
};
export default exports;