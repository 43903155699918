var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "N O P Q R S V W X Y Z a b c d e f g T h H",
      "2": "C K L",
      "322": "G M"
    },
    C: {
      "1": "8 AB BB CB DB EB FB HB IB JB KB LB MB hB iB OB PB U QB RB SB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w tB uB",
      "194": "0 1 2 3 4 5 6 7 x y z",
      "513": "9 GB NB TB"
    },
    D: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "2": "0 1 2 3 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z",
      "4": "4 5 6 7 8"
    },
    E: {
      "1": "C K L G eB fB 2B 3B 4B nB oB 5B",
      "2": "I i J D E F A B xB lB yB zB 0B 1B mB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "F B C G M N O j k l m n o p q 6B 7B 8B 9B eB pB AC fB",
      "4": "r s t u v"
    },
    G: {
      "1": "LC MC NC OC PC QC RC SC TC UC nB oB",
      "2": "E lB BC qB CC DC EC FC GC HC IC JC KC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "2": "gB I WC XC YC ZC qB aC bC",
      "4": "H"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C eB pB fB",
      "4": "U"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "4": "pC"
    },
    S: {
      "2": "qC"
    }
  },
  B: 4,
  C: "Service Workers"
};
export default exports;