var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "Z a b c d e f g T h H",
      "2": "C K L G M N O",
      "1220": "P Q R S V W X Y"
    },
    C: {
      "1": "dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "16": "sB gB tB uB",
      "548": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB"
    },
    D: {
      "1": "Z a b c d e f g T h H kB vB wB",
      "16": "I i J D E F A B C K L",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U",
      "196": "QB RB SB",
      "1220": "TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y"
    },
    E: {
      "1": "L G 3B 4B nB oB 5B",
      "2": "I xB lB",
      "16": "i",
      "164": "J D E yB zB 0B",
      "260": "F A B C K 1B mB eB fB 2B"
    },
    F: {
      "1": "aB bB cB dB P Q R jB S",
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "164": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB",
      "196": "GB HB IB",
      "1220": "JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB"
    },
    G: {
      "1": "SC TC UC nB oB",
      "16": "lB BC qB CC DC",
      "164": "E EC FC",
      "260": "GC HC IC JC KC LC MC NC OC PC QC RC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "16": "gB WC XC YC",
      "164": "I ZC qB aC bC"
    },
    J: {
      "16": "D",
      "164": "A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "cC"
    },
    P: {
      "1": "mC nC",
      "164": "I dC eC fC gC hC mB iC jC kC lC"
    },
    Q: {
      "1220": "oC"
    },
    R: {
      "164": "pC"
    },
    S: {
      "548": "qC"
    }
  },
  B: 5,
  C: ":is() CSS pseudo-class"
};
export default exports;