var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F rB",
      "8": "A B"
    },
    B: {
      "1": "P Q R S V W X Y Z a b c d e f g T h H",
      "8": "C K L G M N O"
    },
    C: {
      "1": "PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t tB uB",
      "8": "0 1 2 3 4 5 6 7 8 9 u v w x y z AB BB CB DB",
      "456": "EB FB GB HB IB JB KB LB MB",
      "712": "hB NB iB OB"
    },
    D: {
      "1": "SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "2": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB",
      "8": "GB HB",
      "132": "IB JB KB LB MB hB NB iB OB PB U QB RB"
    },
    E: {
      "2": "I i J D xB lB yB zB 0B",
      "8": "E F A 1B",
      "132": "B C K L G mB eB fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "0 1 2 3 4 F B C G M N O j k l m n o p q r s t u v w x y z 6B 7B 8B 9B eB pB AC fB",
      "132": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB"
    },
    G: {
      "2": "E lB BC qB CC DC EC FC GC HC IC",
      "132": "JC KC LC MC NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "2": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "eC fC gC hC mB iC jC kC lC mC nC",
      "2": "I",
      "132": "dC"
    },
    Q: {
      "132": "oC"
    },
    R: {
      "132": "pC"
    },
    S: {
      "8": "qC"
    }
  },
  B: 1,
  C: "Custom Elements (V1)"
};
export default exports;