var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D E F A rB"
    },
    B: {
      "2": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB",
      "2": "sB gB I i J D E F A B C FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB tB uB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB",
      "2": "FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "E F A B C 1B mB eB",
      "2": "I i J D xB lB yB zB 0B",
      "129": "K L G fB 2B 3B 4B nB oB 5B"
    },
    F: {
      "1": "0 1 2 3 6 8 G M N O j k l m n o p q r s t u v w x y z fB",
      "2": "4 5 7 9 F B C AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S 6B 7B 8B 9B eB pB AC"
    },
    G: {
      "1": "E FC GC HC IC JC KC LC MC",
      "2": "lB BC qB CC DC EC",
      "257": "NC OC PC QC RC SC TC UC nB oB"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "gB I ZC qB aC bC",
      "2": "H WC XC YC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "fB",
      "2": "A B C U eB pB"
    },
    L: {
      "2": "H"
    },
    M: {
      "2": "T"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "2": "cC"
    },
    P: {
      "1": "I",
      "2": "dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "2": "oC"
    },
    R: {
      "16": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 7,
  C: "SPDY protocol"
};
export default exports;