var exports = {};
exports = {
  A: {
    A: {
      "1": "A B",
      "16": "rB",
      "900": "J D E F"
    },
    B: {
      "1": "P Q R S V W X Y Z a b c d e f g T h H",
      "1025": "C K L G M N O"
    },
    C: {
      "1": "FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "900": "sB gB tB uB",
      "1025": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B",
      "16": "i xB",
      "900": "I lB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "16": "F",
      "132": "B C 6B 7B 8B 9B eB pB AC fB"
    },
    G: {
      "1": "BC qB CC DC EC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "16": "lB",
      "2052": "E FC"
    },
    H: {
      "132": "VC"
    },
    I: {
      "1": "gB I YC ZC qB aC bC",
      "16": "WC XC",
      "4097": "H"
    },
    J: {
      "1": "D A"
    },
    K: {
      "132": "A B C eB pB fB",
      "4097": "U"
    },
    L: {
      "4097": "H"
    },
    M: {
      "4097": "T"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "4097": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1025": "qC"
    }
  },
  B: 1,
  C: "maxlength attribute for input and textarea elements"
};
export default exports;